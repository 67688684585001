<template>
  <div class="campaign wfill" style="padding: 40px;">
    <div style="position: relative; text-align: center;">
      <!-- div class="main" :style="{
            background: `#fafafa`,
          }">
      </div -->
      <img
        :class="{ hiddenOnPage: isPage }"
        v-if="$mq == 'mobile'"
        :src="src"
        width="80%"
        height="auto"
        style="margin: auto"
      />
      <img
        :class="{ hiddenOnPage: isPage }"
        v-else
        :src="src"
        width="auto"
        height="360"
        style="margin: auto"
      />
      <!-- div class="main" :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            background: `url(${src}) center center / contain no-repeat`
          }">
      </div -->
    </div>
    <div
      :class="{ hiddenOnPage: isPage }"
      v-if="showImages && campaign.Images && campaign.Images.length > 0"
      class="images flex"
    >
      <!--button class="chevrons" @click="scrollImagesLeft" style="
          position: absolute;
          left: 8px
          top: calc(50% - 20px);
      ">
        <i class="chevron left icon"></i>
      </button -->
      <img
        v-for="i in [campaign.Poster, ...campaign.Images]"
        :height="$mq == 'mobile' ? 80 : 160"
        :class="{ inactive: i.url != src }"
        class="campaign_img"
        :key="i.url"
        :src="i.url"
        @click="src = i.url"
        alt=""
      />
      <!-- button class="chevrons" @click="scrollImagesRight" style="
          position: absolute;
          right: 8px;
          top: calc(50% - 20px);
      ">
        <i class="chevron right icon"></i>
      </button -->
    </div>
    <h1 class="fs24 mobile-fs16 title mt24 spaced flex">
      <span>{{ campaign.name }}</span>
      <div>
        <button
          @click="
            $router.push(
              `/dashboard?activeEvent=${campaign.eventId}&state=eventRegistrations`
            )
          "
          class="black"
        >
          View Registrations
        </button>
      </div>
    </h1>

    <button
      v-if="campaign.type == 'campaign' && isActive"
      @click="$bus.redirect(campaign.participationLink, false, true)"
      class="participation_button mt16 black"
    >
      {{ campaign.participationLinkText || "Participate Now" }}
    </button>
    <div v-if="campaign.noRegistrations">
      <button
        v-if="campaign.participationLink && isActive"
        @click="$bus.redirect(campaign.participationLink)"
        class="participation_button mt16 black"
      >
        {{ campaign.participationLinkText || "Participate Now" }}
      </button>
    </div>
    <div v-if="campaign.googlePhotosUrl">
      <h6 class="fs16 mt32 mb12" style="padding-left: 6%;">Photos</h6>
      <gallery-widget
        style="padding-left: calc(6% - 8px);"
        :album="campaign.googlePhotosUrl"
      />
    </div>
    <p
      :class="{ hiddenOnPage: isPage }"
      class="info mt24"
      v-html="campaign.description"
    ></p>
    <div>
      <div
        :class="{ hiddenOnPage: isPage }"
        v-if="campaign.files && campaign.files.length > 0"
      >
        <span v-for="f in campaign.files" :key="f.id">
          <h6 class="fs16 mt32 mb12 capitalize" style="padding-left: 6%;">
            {{ f.Type }}
          </h6>
          <a
            class="files pointer mt4"
            @click="$bus.redirect(getFileUrl(f), false, true)"
            :key="f.Name"
            >{{ f.Title }}</a
          >
        </span>
      </div>
    </div>
    <div
      v-for="s in campaign.sections"
      :key="s.id"
      :style="processStyles(s.styles)"
    >
      <div v-if="s.type == 'html'" v-html="s.html"></div>
      <div
        v-if="s.type == 'text'"
        style="padding: 40px 6%"
        v-html="s.text"
      ></div>
      <div
        v-if="s.type == 'textNimage'"
        class="textNimage"
        style="padding: 40px 6%"
      >
        <img style="display: block" height="100%" :src="s.images[0].url" />
        <div v-html="s.text" class="px20 text"></div>
      </div>
      <img
        v-if="$mq == 'desktop' && s.images && s.images[0] && s.type == 'image'"
        class="mxauto"
        style="display: block; height: 70vh; text-align: center;"
        :src="s.images[0].url"
      />
      <img
        v-if="$mq != 'desktop' && s.images && s.images[0] && s.type == 'image'"
        style="width: 100%;"
        :src="s.images[0].url"
      />
    </div>
    <iframe
      :class="{ hiddenOnPage: isPage }"
      v-if="campaign.videoUrl"
      style="width: 80%; margin-left: 6%; margin-top: 20px"
      height="400"
      :src="campaign.videoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <div
      :class="{ hiddenOnPage: isPage }"
      v-if="campaign.Files && campaign.Files.length > 0"
    >
      <h6 class="fs16 mt32 mb12" style="padding-left: 6%;">Attachments</h6>
      <a
        class="files pointer mt4"
        v-for="f in campaign.Files"
        @click="$bus.redirect(getFileUrl(f), false, true)"
        :key="f.name"
        >{{ f.name }}</a
      >
    </div>
    <div
      :class="{ hiddenOnPage: isPage }"
      style="padding-left: 6%;"
      v-for="(g, i) in campaign.FileGroups"
      :key="i + '-' + g.title"
    >
      <h6 class="fs16 mt32 mb12">{{ g.Title }}</h6>
      <div
        class="fileView mt8 mr8"
        v-for="file in g.Files"
        :key="file.url"
        style="background: #eee; display: inline-flex; padding-right: 12px;"
      >
        <p style="font-size: 8px; position: absolute; left: 8px; bottom: 4px;">
          {{ round(file.size / 1000) }} MB
        </p>
        <i
          style="margin: auto 0px auto 12px; color: #8D6235"
          class="file icon"
        ></i>
        <p
          @click="$bus.redirect(file.url, true)"
          style="margin: auto 12px auto 12px;"
          class="fileName pointer"
        >
          {{ file.name }}
        </p>
      </div>
    </div>
    <div :class="{ hiddenOnPage: isPage }" v-if="campaign.noRegistrations">
      <button
        v-if="campaign.participationLink && isActive"
        @click="$bus.redirect(campaign.participationLink, false, true)"
        class="participation_button mt16 black"
      >
        {{ campaign.participationLinkText || "Participate Now" }}
      </button>
    </div>
  </div>
</template>

<script>
import { Notyf } from "notyf";
import $ from "jquery";
import moment from "moment";
import galleryWidget from "../components/gallery-widget";
export default {
  name: "campaign",
  props: {
    campaign: { required: true },
    showImages: { default: true },
    isPage: { default: false }
  },
  data() {
    return {
      src: "",
      loaded: false,
      hasSubmission: false,
      submission: null,
      quizEntry: null,
      roles: undefined,
      yourAge: "",
      teamMember: "",
      teamMemberAge: "",
      teamMembers: []
    };
  },
  components: {
    galleryWidget
  },
  computed: {
    eligible: function() {
      return this.checkEligible(this.$bus.user);
    },
    ineligibleText: function() {
      let type = this.campaign.ParticipantType;
      type = type != "Faculty" ? type + "s" : type;
      return `${this.campaign.ParticipantField} ${type}, please complete profile to participate.`;
    },
    registrationsClosed: function() {
      var to = this.campaign.End;
      var regD = this.campaign.registrationDeadline;
      if (regD) {
        return moment(regD).unix() < moment().unix();
      }
      return moment(to).unix() < moment().unix();
    },
    isActive: function() {
      var from = this.campaign.Start;
      var to = this.campaign.End;
      let convert = m => parseInt(m.format("YYYYMMDD"));
      return (
        convert(moment(from)) <= convert(moment()) &&
        convert(moment(to)) >= convert(moment())
      );
    },
    hasRequiredTeamMembers: function() {
      if (!this.campaign.requiredTeamMembers) {
        return true;
      }
      if (this.campaign.requiredTeamMembers <= this.teamMembers.length + 1) {
        return true;
      }
      return false;
    },
    hasBegun: function() {
      var from = this.campaign.Start;
      return moment(from).unix() <= moment().unix();
    },
    hasEnded: function() {
      var to = this.campaign.End;
      return moment(to).unix() < moment().unix();
    },
    juryEmails: function() {
      return (this.campaign.jury || []).map(j => j.email);
    },
    userEmail: function() {
      return this.$bus.user ? this.$bus.user.email : "";
    }
  },
  methods: {
    round: function(n) {
      return Math.round(n);
    },
    scrollImagesRight: function() {
      $(".images").scrollLeft($(".images").scrollLeft() + 60);
    },
    scrollImagesLeft: function() {
      $(".images").scrollLeft($(".images").scrollLeft() - 60);
    },
    processStyles: function(styleArray) {
      let obj = {};
      if (!styleArray) {
        return obj;
      }
      for (let el of styleArray) {
        obj[el.property] = el.value;
      }
      return obj;
    },
    checkEligible: function(u) {
      let eligibl = true;
      if (
        this.campaign.ParticipantType &&
        this.campaign.ParticipantType != "All"
      ) {
        eligibl = this.campaign.ParticipantType == u.type;
      }
      if (
        this.campaign.ParticipantField &&
        this.campaign.ParticipantField != "All"
      ) {
        eligibl = eligibl && this.campaign.ParticipantField == u.field;
      }
      return eligibl;
    },
    simulateClick: function() {
      $(this.$refs.fileInput).click();
    },
    deleteFile: function(id) {
      this.$req
        .delete(`upload/files/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`
          }
        })
        .then(() => {
          let ix = this.submission.files.findIndex(f => f.id == id);
          this.submission.files.splice(ix, 1);
        })
        .catch(e => console.log(e));
    },
    addTeamMember: async function() {
      if (this.teamMember == "") {
        alert("Please fill all inputs");
        return;
      }
      if (this.teamMemberAge == "" && this.campaign.pageName == "LEDEG") {
        alert("Please fill all inputs");
        return;
      }
      if (this.teamMember == this.$bus.user.email) {
        alert("Cannot add yourself to your own team");
        return;
      }

      let user = await this.$req
        .get(`getUserId?email=${this.teamMember}`)
        .catch(e => console.log(e));
      user = user.data;
      if (!this.checkEligible(user)) {
        alert("User is not eligible for this competition.");
        return;
      }

      if (
        this.campaign.sameRegionTeam &&
        this.$bus.getRegion(user.institute) !=
          this.$bus.getRegion(this.$bus.user.institute)
      ) {
        alert("All team members must be from the same region.");
        return;
      }

      if (user == "User does not exist") {
        alert("User does not have an account. Please ask user to sign up.");
      } else if (!this.$bus.checkProfileCompletion(user)) {
        alert(
          "User has incomplete profile. Please ask user to complete their profile for participation."
        );
      } else {
        this.teamMembers.push({
          ...user,
          age:
            this.campaign.pageName == "LEDEG" ? this.teamMemberAge : undefined
        });
        this.teamMember = "";
        this.teamMemberAge = "";
      }
    },
    deleteTeamMember: function(ix) {
      this.teamMembers.splice(ix, 1);
    },
    getDateLabel: function(date) {
      return moment(date).format("HH:mm Do MMM YYYY");
    },
    createFormData(file, collection, field, source = "") {
      let data = new FormData();
      data.append("files", file);
      data.append("ref", collection);
      data.append("refId", this.submission.id);
      if (source) {
        data.append("source", source);
      }
      data.append("field", field);
      return data;
    },
    submitFile: function(ev) {
      var MAX_FILE_SIZE = 25 * 1024 * 1024; // 5MB
      let files = ev.target.files[0];
      let cumulative =
        this.submission.files.reduce((s, f) => s + f.size * 1024, 0) +
        files.size;
      if (cumulative > MAX_FILE_SIZE) {
        alert("Submissions cannot exceed 25 Mb");
        return;
      }
      let notyf = new Notyf({ position: { x: "right", y: "top" } });
      notyf.success("Submission upload started");
      let data = this.createFormData(files, "event-submissions", "files");
      this.$baseReq
        .post("upload", data, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`
          }
        })
        .then(r => {
          notyf.success("Submission Received");
          this.submission.files.push(r.data[0]);
          this.$req
            .post(
              "sendSubmissionEmail",
              {
                event: this.campaign.Title,
                name: this.$bus.user.name,
                to: this.$bus.user.email
              },
              this.$bus.headers
            )
            .catch(e => console.log(e));
        })
        .catch(e => {
          notyf.success("Upload Failed");
          console.log(e);
        });
    },
    createSubmission: function() {
      if (this.yourAge == "" && !this.campaign.pageName == "LEDEG") {
        alert("Please enter your age");
        return;
      }

      this.$req
        .post(
          "createEventEntry",
          {
            event: this.campaign.id,
            users: [this.$bus.user.id, ...this.teamMembers.map(m => m.id)],
            metadata:
              this.campaign.pageName == "LEDEG"
                ? { ages: [this.yourAge, ...this.teamMembers.map(m => m.age)] }
                : undefined
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`
            }
          }
        )
        .then(r => {
          this.submission = r.data;
        })
        .catch(e => console.log(e));
    },
    updateSubmission: function() {
      this.$req
        .put(
          `event-submissions/${this.submission.id}`,
          {
            users: [this.$bus.user.id, ...this.teamMembers.map(m => m.id)],
            metadata:
              this.campaign.pageName == "LEDEG"
                ? { ages: [this.yourAge, ...this.teamMembers.map(m => m.age)] }
                : undefined
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`
            }
          }
        )
        .then(r => {
          this.submission = r.data;
        })
        .catch(e => console.log(e));
    },
    getFileUrl: function (file) {
      if (file) {
        if (file.Files.length > 0) {
          return file.Files[0].url;
        } else if (file.Url && file.Url.includes('http')) {
          return file.Url
        } else if (file.Url) {
          return 'https://ethosstrapibucket.s3.ap-south-1.amazonaws.com/eventfiles/' + file.Url
        }
      }
      return ''
    },
    getOldEventPoster: function(oldEvent) {
      let files = oldEvent.files;
      let poster = files.find(f => f.Type == "poster");
      if (poster) {
        if (poster.Files.length > 0) {
          return poster.Files[0].url;
        } else if (poster.Url && poster.Url.includes('http')) {
          return poster.Url
        } else if (poster.Url) {
          return 'https://ethosstrapibucket.s3.ap-south-1.amazonaws.com/eventfiles/' + poster.Url
        }
      }
      return "";
    }
  },
  async mounted() {
    this.src = this.getOldEventPoster(this.campaign);
    this.$bus.getLatestRoles().then(r => {
      this.roles = r;
    });
  }
};
</script>

<style lang="css" scoped>
.chevrons {
  width: 40px;
  padding: 8px;
  background: white;
  border-radius: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.12);
}
.chevrons:hover {
  background: #f5f5f5;
}
.chevrons:active {
  background: #eee;
}
.chevrons i {
  margin: auto;
}
.campaign .figure .campaign:not(.event) {
  /* background: url('../assets/campaign_smear.svg'); */
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.title,
.info,
.files {
  padding-left: 6%;
  padding-right: 6%;
}
a {
  display: block;
}
.participation_button {
  margin-left: 6%;
}
div.main {
  margin-left: 6%;
  width: 80%;
  height: 360px;
}
@media (min-width: 1400px) {
  div.main {
    height: 500px;
  }
}
@media (max-width: 800px) {
  a {
    font-size: 12px;
  }
}

.images {
  margin-left: 6%;
  margin-top: 24px;
  overflow-x: scroll;
  width: 80%;
  position: relative;
}
.images img {
  cursor: pointer;
  margin-right: 12px;
  margin-left: 0px;
}
.images::-webkit-scrollbar {
  display: none;
}
.campaign_img.inactive {
  opacity: 0.4;
}

/* Hide scrollbar for IE, Edge and Firefox */
.images {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.fileUpload {
  background: #eee;
  border-radius: 4px;
  padding: 12px 20px;
}
.addFileButton {
  width: 150px;
  color: #aaa;
  height: 50px;
  background: transparent;
  border: 1px dashed #ccc;
  transition: 0.15s;
}
.addFileButton:hover {
  background: #f4f4f4;
}
.fileView {
  display: flex;
  position: relative;
  min-width: 150px;
  height: 50px;
  border-radius: 4px;
  background: white;
}
.fileName:hover {
  color: #8d6235;
}
.fileDelete {
  color: #ccc;
}
.fileDelete:hover {
  color: tomato;
}
.hiddenOnPage {
  display: none;
}
</style>
